import React from 'react';
import { AppBar, Avatar, Card, CardMedia, CardContent, Typography, Box, Grid } from '@material-ui/core';

function App() {
  return (
    <div className="App">
      <div>
        <AppBar position="static">
          <h1>Alessandro Aneggi</h1>
        </AppBar>
      </div>
      
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Avatar
              alt="Remy Sharp"
              src="/images/camicia-mia.jpg"
              sx={{ width: 56, height: 56 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ display: 'flex' }}>
              <CardMedia
                component="img"
                sx={{ width: 151 }}
                image="/images/camicia-mia.jpg"
                alt="Live from space album cover"
              />
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography component="div" variant="h5">
                    Live From Space
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary" component="div">
                    Mac Miller
                  </Typography>
                </CardContent>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default App;